<template>
    <card-list>
        <subheader-list-item icon="fa-ban" title="Override Event"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="event.active">Participant will attend this event.</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Description/Notes" label-position="inside">
                        <b-input type="textarea" v-model="event.description"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>

        <subheader-list-item icon="fa-tasks" title="Task"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Status" label-position="inside">
                        <b-select expanded v-model="event.task_status">
                            <option value="planned">Planned</option>
                            <option value="booked">Booked</option>
                            <option value="confirmed">Confirmed</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Payment" label-position="inside">
                        <b-select expanded v-model="event.task_payment">
                            <option value="unpaid">Unpaid</option>
                            <option value="deposit">Deposit</option>
                            <option value="paid">Paid</option>
                            <option value="na">N/A</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Assignment" label-position="inside">
                        <b-select expanded v-model="event.task_assigned_id">
                            <option :value="a.id" v-for="a in assignees">{{a.first_name}} {{a.last_name}}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-switch :true-value="1" :false-value="0" v-model="event.task_complete">Complete</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Notes" label-position="inside">
                        <b-input type="textarea" v-model="event.task_notes"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import debounce from 'debounce';
    import {client as http} from '../../http_client';
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import {mapGetters} from "vuex";


    export default {
        name: 'OverrideForm',
        props: {
            event: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList},
        data: function () {
            return {

            };
        },
        computed: {
            ...mapGetters(['assignees']),
        },
        methods: {

        }
    };
</script>
